<template>
  <div class="page" style="margin-top: -80px">
    <div id="printDiv" v-show="printShow">
      <COAOilEhxto :printModel="this.$data" style="height-max: 1000px; width: 100vw" ></COAOilEhxto>
    </div>
    <div v-if="isShowAlonePrint" style="width:21cm;margin-left:25%;">
      <div id="alonePrintDiv">
        <COAOilEhxto :printModel="this.$data" style="height-max: 1000px;" ></COAOilEhxto>
      </div>
      <div style="margin-top: 50px;">
        <el-button type="info" icon="el-icon-edit" @click="showUpdate()" v-if="isShowUpdate">Update</el-button>
        <el-button type="success" icon="el-icon-printer" @click="aloneprintPage()">Print</el-button>
        <el-button type="info" @click="closePage()">Exit</el-button>
      </div>
    </div>
    <center v-else>
      <div class="new-item-content" style="margin: 40px 0 40px 0;">
        <!-- Base Info -->
        <div class="base-info">
          <div></div>
          <div>
            <div>
              <span>Edit Print Title：</span>

              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoEditPrintTitleCheckbox"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.BaseInfoEditPrintTitleCheckbox"
                  v-model="form.BaseInfoEditPrintTitle"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 575px"
                ></el-input>
              </div>
            </div>
            <!-- <div>
              <span>Custom Product Name：</span>
              <el-input
                v-model="form.BaseInfoCustomProductName"
                placeholder="Please Input"
              ></el-input>
            </div> -->
            <div>
              <span>Country of Origin：</span>
              <el-input
                v-model="form.BaseInfoCountryofOrigin"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div>
              <span>Customer PO Number：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoCustomerPONumber"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.BaseInfoCustomerPONumber"
                  v-model="form.BaseInfoCustomerPONumberText"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 575px"
                ></el-input>
              </div>
            </div>
            <div>
              <span>Customer Code：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoCustomerCodeCheckbox"
                ></el-checkbox>
                <el-input
                  v-model="form.BaseInfoCustomerCode"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 575px"
                  v-show="form.BaseInfoCustomerCodeCheckbox"
                ></el-input>
              </div>
            </div>
            <div style="margin: 0 0 0 0">
              <span style="width: 500px; margin-left: -200px"
                >Country of Origin Feedstock and Country of Origin
                Extraction：</span
              >
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoCOfOFAndCOfOE"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-model="form.BaseInfoCOfOFAndCOfOEText"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 575px"
                  v-show="form.BaseInfoCOfOFAndCOfOE"
                ></el-input>
              </div>
            </div>
            <div>
              <span>SAP Number：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox v-model="form.BaseInfoSAPNumber"></el-checkbox>
                <el-input
                  v-model="form.BaseInfoSAPNumberText"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 575px"
                  v-show="form.BaseInfoSAPNumber"
                ></el-input>
              </div>
            </div>
            <div>
              <span>Manufacturing Date：</span>
              <el-date-picker
                type="month"
                v-model="form.BaseInfoManufacturingDate"
                placeholder="Please Select Date"
                @on-change="date=$event"
                @change="manufacturingDateChange"
              ></el-date-picker>
            </div>
            <div>
              <span>Expiration Date：</span>
              <el-date-picker
                type="month"
                v-model="form.BaseInfoExpirationDate"
                placeholder="Please Select Date"
                @on-change="date=$event"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <!-- General Specification -->
        <div class="base-info">
          <div>General Specification</div>
          <div>
            <div style="height: auto">
              <span>CommonName：</span>
              <!-- <el-input
                v-model="form.GeneralSpecCommonName"
                placeholder="Please Input"
              ></el-input> -->

              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in commonNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(commonNameArr, tag, i)"
                    @close="handleClose(tag, 'commonNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="commonNameInputVisible"
                    v-model="commonNameInputValue"
                    ref="commonNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="commonNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'commonNameInputVisible',
                        'commonNameSaveTagInput'
                      )
                    "
                    >+ New CommonName</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>INCI name：</span>
              <!-- <el-input
                v-model="form.GeneralSpecINCIName"
                placeholder="Please Input"
              ></el-input> -->

              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in inclNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(inclNameArr, tag, i)"
                    @close="handleClose(tag, 'inclNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="inclNameInputVisible"
                    v-model="inclNameInputValue"
                    ref="inclNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="inciNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('inclNameInputVisible', 'inclNameSaveTagInput')
                    "
                    >+ New InclName</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>Botanical Name：</span>
              <!-- <el-input
                v-model="form.GeneralSpecBotanicalName"
                placeholder="Please Input"
              ></el-input> -->
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in botanicalNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(botanicalNameArr, tag, i)"
                    @close="handleClose(tag, 'botanicalNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="botanicalNameInputVisible"
                    v-model="botanicalNameInputValue"
                    ref="botanicalNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="botanicalNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'botanicalNameInputVisible',
                        'botanicalNameSaveTagInput'
                      )
                    "
                    >+ New botanicalName</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>Part Used：</span>
              <!-- <el-input
                v-model="form.GeneralSpecPartUsed"
                class="custom-row"
                placeholder="Please Input"
              ></el-input> -->

              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in partUsedArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(partUsedArr, tag, i)"
                    @close="handleClose(tag, 'partUsedArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="partUsedInputVisible"
                    v-model="partUsedInputValue"
                    ref="partUsedSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="partUsedD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('partUsedInputVisible', 'partUsedSaveTagInput')
                    "
                    >+ New Part Used</el-button
                  >
                </div>
              </div>
            </div>
            
          <div style="height: auto">
            <div style="display: inline-block; text-align: left">
            <table style="border: 1px solid #eee;">
              <tr>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="Please Input" style="width: 100%" v-model="form.tableInput1"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="Please Input" style="width: 100%" v-model="form.tableInput2"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="Please Input" style="width: 100%" v-model="form.tableInput3"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="Please Input" style="width: 100%" v-model="form.tableInput4"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="Please Input" style="width: 100%" v-model="form.tableInput5"></el-input>
                </td>
                <td>
                  <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                </td>
              </tr>
              <tr v-for="(item, tableInputIndex) in tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="Please Input" style="width: 100%" v-model="item.tableInput1"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="Please Input" style="width: 100%" v-model="item.tableInput2"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="Please Input" style="width: 100%" v-model="item.tableInput3"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="Please Input" style="width: 100%" v-model="item.tableInput4"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="Please Input" style="width: 100%" v-model="item.tableInput5"></el-input>
                </td>
                <td>
                  <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                  <el-button type="danger" class="button-new-tag" size="small" @click="addTableInput(0, tableInputIndex)">-</el-button>
                </td>
              </tr>
            </table>
            </div>
          </div>

            <div>
              <span>Appearance：</span>
              <el-input
                v-model="form.GeneralSpecAppearance"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div>
              <span>Solubility：</span>
              <el-input
                v-model="form.GeneralSpecSolubility"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div>
              <span>Important Constituents in Plant：</span>
              <el-input
                v-model="form.GeneralSpecImportantConstituentsInPlant"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div>
              <span>Homogeneity：</span>
              <el-input
                v-model="form.GeneralSpecHomogeneity"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div>
              <span>Extraction Process：</span>
              <el-input
                v-model="form.GeneralSpecExtractionProcess"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div v-show="false">
              <span>Processing Aid：</span>
              <el-input
                v-model="form.GeneralSpecProcessingAid"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div>
              <span>Solvent for Extraction：</span>
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecSolventForExtraction"
                  class="custom-row"
                  placeholder="Please Input"
                ></el-input>
              </div>
            </div>
            <div>
              <span>Carrier：</span>
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCarrier"
                  class="custom-row"
                  placeholder="Please Input"
                ></el-input>
              </div>
            </div>
            <div>
              <span>Pesticides：</span>
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecPesticides"
                  class="custom-row"
                  placeholder="Please Input"
                ></el-input>
              </div>
            </div>
            <div>
              <span>Certification：</span>
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCertification"
                  class="custom-row"
                  placeholder="Please Input"
                ></el-input>
              </div>
            </div>

            <div>
              <span style="margin-left: 48px">
                <el-input
                  v-model="form.GeneralSpecCustomRowKey1"
                  class="custom-row"
                  placeholder="Please Name Input"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCustomRowValue1"
                  class="custom-row"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, 0)"
                >+</el-button>
            </div>

            <div
              v-for="(item, genSpecIndex) in genSpecCustomFieldAttr"
              :key="genSpecIndex + 1"
            >
              <span style="margin-left: 96px">
                <el-input
                  v-model="item.customKey"
                  placeholder="Please Name Input"
                  class="custom-row"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="item.customValue"
                  class="custom-row"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, genSpecIndex)"
                >+</el-button
              >
              <el-button
                type="danger"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(0, genSpecIndex)"
                >-</el-button
              >
            </div>
          </div>
        </div>
        <!-- Analysis -->
        <div class="base-info">
          <div>Analysis</div>
          <div>
            <table
              class="AnalysisTable"
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td style="width: 25%" class="FontWeight">Test Item</td>
                <td style="width: 25%" class="FontWeight">Specification</td>
                <td style="width: 25%" class="FontWeight">Result</td>
                <td style="width: 25%" class="FontWeight">Test Method</td>
              </tr>

              <tr>
                <td class="FontWeight">Moisture</td>
                <td>
                  <el-input
                    v-model="form.SpecificationMoisture"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultMoisture"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodMoisture"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Specific Gravity</td>
                <td>
                  <el-input
                    v-model="form.SpecificationSpecificGravity"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultSpecificGravity"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodSpecificGravity"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Refractive Index</td>
                <td>
                  <el-input
                    v-model="form.SpecificationRefractiveIndex"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultRefractiveIndex"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodRefractiveIndex"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">Optical Rotation</td>
                <td>
                  <el-input
                    v-model="form.SpecificationOpticalRotation"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultOpticalRotation"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodOpticalRotation"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Total Heavy Metals</td>
                <td>
                  <el-input
                    onkeyup="value=value.replace(/[^\d.]|/g,'')"
                    v-model="form.SpecificationTotalHeavyMetals"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTotalHeavyMetals"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTotalHeavyMetals"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Arsenic (AS)</td>
                <td>
                  <el-input
                    v-model="form.SpecificationArsenicAS"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultArsenicAS"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodArsenicAS"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Cadmium (Cd)</td>
                <td>
                  <el-input
                    v-model="form.SpecificationCadmiumCd"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template></el-input
                  >
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCadmiumCd"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCadmiumCd"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Lead (Pd)</td>
                <td>
                  <el-input
                    v-model="form.SpecificationLeadPd"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template></el-input
                  >
                </td>
                <td>
                  <el-input
                    v-model="form.ResultLeadPd"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodLeadPd"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">Mercury (Hg)</td>
                <td>
                  <el-input
                    v-model="form.SpecificationMercuryHg"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template></el-input
                  >
                </td>
                <td>
                  <el-input
                    v-model="form.ResultMercuryHg"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodMercuryHg"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Color</td>
                <td>
                  <el-input
                    v-model="form.SpecificationColor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultColor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodColor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Odor</td>
                <td>
                  <el-input
                    v-model="form.SpecificationOdor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultOdor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodOdor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Taste</td>
                <td>
                  <el-input
                    v-model="form.SpecificationTaste"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTaste"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTaste"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey2"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue2"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue2"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue2"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey3"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue3"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue3"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue3"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey4"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue4"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue4"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue4"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, 0)"
                  >+</el-button>
              </tr>

              <tr
                v-for="(item, analysisIndexEhxto) in analysisCustomFieldAttr"
                :key="analysisIndexEhxto"
              >
                <td class="FontWeight">
                  <el-input
                    v-model="item.customKey"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue2"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <el-button
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, analysisIndexEhxto)"
                  >+</el-button
                >
                <el-button
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(0, analysisIndexEhxto)"
                  >-</el-button
                >
              </tr>
            </table>
          </div>
        </div>
        <div class="base-info">
          <div>Microbiological Tests</div>
          <div>
            <table
              class="AnalysisTable"
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td style="width: 25%" class="FontWeight">Test Item</td>
                <td style="width: 25%" class="FontWeight">Specification</td>
                <td style="width: 25%" class="FontWeight">Result</td>
                <td style="width: 25%" class="FontWeight">Test Method</td>
              </tr>
              <tr>
                <td class="FontWeight">Total Plate Count</td>
                <td>
                  <el-input
                    onkeyup="value=value.replace(/[^\d.]|/g,'')"
                    v-model="form.SpecificationTotalPlateCount"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                  <!-- <el-input
                    v-model="form.SpecificationTotalPlateCount"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input> -->
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTotalPlateCount"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTotalPlateCount"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Yeast and Mold</td>
                <td>
                  <el-input
                    onkeyup="value=value.replace(/[^\d.]|/g,'')"
                    v-model="form.SpecificationYeastAndMold"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                  <!-- <el-input
                    v-model="form.SpecificationYeastAndMold"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input> -->
                </td>
                <td>
                  <el-input
                    v-model="form.ResultYeastAndMold"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodYeastAndMold"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">E. Coli</td>
                <td>
                  <el-input
                    v-model="form.SpecificationEColi"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultEColi"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodEColi"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Salmonella</td>
                <td>
                  <el-input
                    v-model="form.SpecificationSalmonella"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultSalmonella"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodSalmonella"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Staph Aureus</td>
                <td>
                  <el-input
                    v-model="form.SpecificationStaphAureus"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultStaphAureus"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodStaphAureus"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Total Coliforms</td>
                <td>
                  <el-input                    
                    @blur="checkSpecificationTotalColiforms()"
                    v-model="form.SpecificationTotalColiforms"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">MPN/g</div>
                    </template>
                  </el-input>
                  <!-- <el-input
                    v-model="form.SpecificationTotalColiforms"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input> -->
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTotalColiforms"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTotalColiforms"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.MTSpecificationCustomRowKey1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTSpecificationCustomRowValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTResultCustomRowValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTTestMethodCustomRowValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, 0)"
                  >+</el-button
                >
              </tr>

              <tr
                v-for="(item, micTestsIndex) in micTestsCustomFieldAttr"
                :key="micTestsIndex"
              >
                <td class="FontWeight">
                  <el-input
                    v-model="item.customKey"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue2"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <el-button
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, micTestsIndex)"
                  >+</el-button
                >
                <el-button
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(0, micTestsIndex)"
                  >-</el-button
                >
              </tr>
            </table>
          </div>
        </div>
        <NoteTableData :baseInfo="this.$data"></NoteTableData>
        
        <div class="other-notes">
          <div>Notes</div>
          <div style="margin-top:0px;">
            <el-input v-model="newNote" placeholder="Please Input" style="width:600px;margin-bottom:10px;margin-right:10px;"></el-input>
            <el-button type="text" @click="addNewNoteTableClick">
              Complete
            </el-button>
          </div>
        </div>

        <div class="base-info">
          <div>Note:</div>
          <div style="width: 80%; text-align: left; font-weight: bold">
            <div>
              * <el-checkbox v-model="form.note1">
                  Manufacturing Site: Shanghai Tianyuan Plant Products Company, Ltd.  No.18 Hexiang Rd, Baihe Town, Qingpu District, Shanghai.
                </el-checkbox>
            </div>
            <div>
              * <el-checkbox v-model="form.note2">
                  The above listed product is non-irradiated product.
                </el-checkbox>
            </div>
            <div>
              * <el-checkbox v-model="form.note3">
                  This product is intended only for animal use and is not intended for human consumption.
                </el-checkbox>
            </div>
            <div>
              *  Color variation from batch to batch of the product may occur due to natural variations of raw material.
            </div>
            <div style="height:28px;line-height: 28px;">
              * Some natural sediment may occur upon standing, that does not compromise the quality of the product; please warm to ambient temperature and mix well before use in such case.
            </div>
            <div v-for="(item, index) in newNoteArr" :key="index" style="margin-top:10px;height:28px;">
              * {{item.content}}
              <el-button type="text" @click="deleteNewNoteTableClick(index)">
                <i style="color:red;font-size:20px;" class="el-icon-delete"></i>
              </el-button>
            </div>
          </div>
        </div>
        <!-- Save -->
        <div class="save-bottom">
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('ContinueEdit')">Save</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="inventoryDialogVisible = true">Upload to library</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="updateBySpec()">COA Update</el-button>
          <el-button @click="back">Back Coa</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printPage()">Print</el-button>
        </div>
      </div>
    </center>
    <el-dialog
      title="Tips"
      :visible.sync="inventoryDialogVisible"
      width="30%"
      style="text-align:left;"
    >
      <span>Please select the type to save</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addToLibrary(1)">Inventory</el-button>
        <el-button type="primary" @click="addToLibrary(2)">Sample Inventory</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import base from "@/common/base.js";
import printJS from "print-js";
import COAOilEhxto from "../PrintProductTypeModule/COA-OilEhxto";
import NoteTableData from "../NoteTableData";
export default {
  props: { baseInfoItemCodeDetail: {},baseinfoCustom:{} },
  mixins: [base],
  components: { COAOilEhxto,NoteTableData },
  data() {
    return {
      inventoryDialogVisible:false,
      addRevNumber:false,
      inclNameArr: [],
      inclNameInputVisible: "",
      inclNameInputValue: "",
      inciNameD: false,

      commonNameArr: [],
      commonNameInputVisible: "",
      commonNameInputValue: "",
      commonNameD: false,

      botanicalNameArr: [],
      botanicalNameInputVisible: "",
      botanicalNameInputValue: "",
      botanicalNameD: false,

      partUsedArr: [],
      partUsedInputVisible: "",
      partUsedInputValue: "",
      partUsedD: false,
      BaseInfoManufacturingDate: "",
      BaseInfoExpirationDate: "",
      printShow: false,
      baseInfoCustomFieldList:[],
      form: {
        baseInfoCustomField:'',
        libraryRevNumber:0,
        libraryCreateTime:'',
        revNumber:1,
        versionNumber:"",
        revDate:[],
        noteTableData:"",
        BaseInfoEditPrintTitle: "",
        BaseInfoEditPrintTitleCheckbox: false,
        productType: "",
        oldProductType: "",
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        ProductSubType: "",
        BaseInfoLot: "",
        BaseInfoCustomProductName: "",
        BaseInfoCountryofOrigin: "China",
        BaseInfoCustomerPONumber: false, //Checkbox
        BaseInfoCustomerPONumberText: "",
        BaseInfoCustomerCodeCheckbox: false,
        BaseInfoCustomerCode: "",
        BaseInfoCOfOFAndCOfOE: false, //Checkbox
        BaseInfoCOfOFAndCOfOEText: "",
        BaseInfoSAPNumber: false, //Checkbox
        BaseInfoSAPNumberText: "",
        BaseInfoManufacturingDate: "",
        BaseInfoExpirationDate: "",

        GeneralSpecCommonName: "",
        //GeneralSpecProductGrade: "",
        GeneralSpecINCIName: "",
        GeneralSpecBotanicalName: "",
        //GeneralSpecCommonNameOrOtherName: "",
        GeneralSpecPartUsed: "",
        GeneralSpecAppearance: "",
        GeneralSpecSolubility: "",
        //GeneralSpecPercentage: "",
        //GeneralSpecExtractionRatio: "",
        GeneralSpecImportantConstituentsInPlant: "",
        GeneralSpecHomogeneity: "",
        GeneralSpecExtractionProcess: "",
        GeneralSpecProcessingAid: "",
        GeneralSpecSolventForExtraction: "",
        GeneralSpecCarrier: "",
        GeneralSpecPesticides: "",
        GeneralSpecCertification: "",

        GeneralSpecCustomRowKey1: "",
        GeneralSpecCustomRowValue1: "",
        GeneralSpecCustomRowKey2: "",
        GeneralSpecCustomRowValue2: "",

        SpecificationMoisture: "",
        SpecificationSpecificGravity: "",
        SpecificationRefractiveIndex: "",
        SpecificationOpticalRotation: "",
        SpecificationTotalHeavyMetals: "",
        SpecificationArsenicAS: "",
        SpecificationCadmiumCd: "",
        SpecificationLeadPd: "",
        SpecificationMercuryHg: "",
        SpecificationColor: "",
        SpecificationOdor: "Characteristic",
        SpecificationTaste: "Characteristic",

        ResultMoisture: "",
        ResultSpecificGravity: "",
        ResultRefractiveIndex: "",
        ResultOpticalRotation: "",
        ResultTotalHeavyMetals: "<10",
        ResultArsenicAS: "",
        ResultCadmiumCd: "",
        ResultLeadPd: "",
        ResultMercuryHg: "",
        ResultColor: "",
        ResultOdor: "Compares to standard",
        ResultTaste: "Compares to standard",

        TestMethodMoisture: "",
        TestMethodSpecificGravity: "",
        TestMethodRefractiveIndex: "",
        TestMethodOpticalRotation: "",
        TestMethodTotalHeavyMetals: "USP <231>",
        TestMethodArsenicAS: "ICP",
        TestMethodCadmiumCd: "ICP",
        TestMethodLeadPd: "ICP",
        TestMethodMercuryHg: "ICP",
        TestMethodColor: "Visual",
        TestMethodOdor: "Organoleptic",
        TestMethodTaste: "Organoleptic",

        SpecificationTotalPlateCount: "",
        SpecificationYeastAndMold: "",
        SpecificationEColi: "",
        SpecificationSalmonella: "",
        SpecificationStaphAureus: "",
        SpecificationTotalColiforms: "",

        ResultTotalPlateCount: "",
        ResultYeastAndMold: "",
        ResultEColi: "Negative",
        ResultSalmonella: "Negative",
        ResultStaphAureus: "Negative",
        ResultTotalColiforms: "<10 MPN/g",

        TestMethodTotalPlateCount: "FDA-BAM",
        TestMethodYeastAndMold: "FDA-BAM",
        TestMethodEColi: "FDA-BAM",
        TestMethodSalmonella: "FDA-BAM",
        TestMethodStaphAureus: "FDA-BAM",
        TestMethodTotalColiforms: "FDA-BAM",

        SpecificationCustomRowKey1: "",
        SpecificationCustomRowValue1: "",
        ResultCustomRowKey1: "",
        ResultCustomRowValue1: "",
        TestMethodCustomRowKey1: "",
        TestMethodCustomRowValue1: "",

        SpecificationCustomRowKey2: "",
        SpecificationCustomRowValue2: "",
        ResultCustomRowKey2: "",
        ResultCustomRowValue2: "",
        TestMethodCustomRowKey2: "",
        TestMethodCustomRowValue2: "",

        SpecificationCustomRowKey3: "",
        SpecificationCustomRowValue3: "",
        ResultCustomRowKey3: "",
        ResultCustomRowValue3: "",
        TestMethodCustomRowKey3: "",
        TestMethodCustomRowValue3: "",

        SpecificationCustomRowKey4: "",
        SpecificationCustomRowValue4: "",
        ResultCustomRowKey4: "",
        ResultCustomRowValue4: "",
        TestMethodCustomRowKey4: "",
        TestMethodCustomRowValue4: "",

        MTSpecificationCustomRowKey1: "",
        MTSpecificationCustomRowValue1: "",

        // MTResultCustomRowKey1: "",
        // MTResultCustomRowValue1: "",
        // MTTestMethodCustomRowKey1: "",
        // MTTestMethodCustomRowValue1: "",

        // MTSpecificationCustomRowKey2: "",
        // MTSpecificationCustomRowValue2: "",
        // MTResultCustomRowKey2: "",
        // MTResultCustomRowValue2: "",
        // MTTestMethodCustomRowKey2: "",
        // MTTestMethodCustomRowValue2: "",

        // MTSpecificationCustomRowKey3: "",
        // MTSpecificationCustomRowValue3: "",
        // MTResultCustomRowKey3: "",
        // MTResultCustomRowValue3: "",
        // MTTestMethodCustomRowKey3: "",
        // MTTestMethodCustomRowValue3: "",

        // MTSpecificationCustomRowKey4: "",
        // MTSpecificationCustomRowValue4: "",
        // MTResultCustomRowKey4: "",
        // MTResultCustomRowValue4: "",
        // MTTestMethodCustomRowKey4: "",
        // MTTestMethodCustomRowValue4: "",

        genSpecCustomField: "",
        analysisCustomField: "",
        micTestsCustomField: "",
        note1:false,
        note2:false,
        note3:false,

        tableInput1:"",
        tableInput2:"",
        tableInput3:"",
        tableInput4:"",
        tableInput5:"",
        tableInputCustomField:"",
      },
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        lot: "",
        jsonValue: {},
        updateName:"",
        updateTime:base.methods.getLocalNowTime(),
        createTime:base.methods.getLocalNowTime(),
      },
      createTime: base.methods.getLocalNowTime(),

      genSpecCustomFieldAttr: [],
      analysisCustomFieldAttr: [],
      micTestsCustomFieldAttr: [],
      customField: {
        customKey: "",
        customValue: "",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: "",
      },
      tableInputCustomFieldAttr: [],
      tableInputField: {
        tableInput1: "",
        tableInput2: "",
        tableInput3: "",
        tableInput4: "",
        tableInput5: "",
      },
      isShowAlonePrint:false,
      isShowUpdate:false,
      shelflifeNumber:0,
      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          reason:"",
          approvedBy: "",
          isComplete:false,
        },
      ],
      newNoteArr:[],
      newNote:"",
    };
  },
  methods: {
    back() {
      this.$router.push({ path: "/coa-items" });
    },
    showUpdate(){
      this.isShowAlonePrint = false
      this.$parent.isShowAlonePrint = false
    },
    closePage(){
      if (navigator.userAgent.indexOf('MSIE') > 0) { // close IE
        if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
          window.opener = null;
          window.close();
        } else {
          window.open('', '_top');
          window.top.close();
        }
      } else { // close chrome;It is effective when it is only one.
        window.opener = null;
        window.open('', '_self');
        window.close();
      }
    },
    checkSpecificationTotalColiforms(){
      this.form.SpecificationTotalColiforms=this.form.SpecificationTotalColiforms.toLowerCase()=='negative'?this.form.SpecificationTotalColiforms:this.form.SpecificationTotalColiforms.replace(/[^\d.]|/g,'')
    },
    updateBySpec(){
      this.$confirm(
        "Are you sure synchronize the spec content. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$api.handleSpec
        .getDetailByItemCode(this.$parent.params.BaseInfoItemCode)
        .then((res) => {
          if (res.jsonValue != undefined) {
            console.log('getDetailByItemCode==res')
            console.log(res)
            this.$parent.params.BaseInfoProductName = res.productName;
            this.$parent.revDateTime =base.methods.getLocalNowTime();
            let jsonValue = JSON.parse(res.jsonValue)
            console.log('revNumber==='+jsonValue.revNumber)
            this.$parent.params.revNumber = jsonValue.revNumber;
            console.log('this.$parent.params.revNumber==='+this.$parent.params.revNumber)

            this.getBaseInfoDetail(jsonValue)
            this.handleNew('ContinueEdit')
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      });
    },
    handleNew(type) {
      let params = { ...this.$route.query };
      console.log("=============");
      console.log(this.$parent.params);
      
      this.form.noteTableData = JSON.stringify(this.noteTableData) 
      this.form.newNoteArr = JSON.stringify(this.newNoteArr) 
      // let revNumber = 0
      // this.noteTableData.forEach(item=>{
      //   if(item.isComplete){
      //     revNumber ++
      //   }
      // })
      // this.form.revNumber = revNumber
      
      this.form.baseInfoCustomField = JSON.stringify(this.$parent.BaseInfoCustomFieldAttr);
      this.form.productType = this.$parent.params.productType;
      this.form.oldProductType = this.$parent.params.oldProductType;
      this.form.ProductSubType = this.$parent.params.ProductSubType;
      this.form.BaseInfoItemCode = this.$parent.params.BaseInfoItemCode;
      this.form.BaseInfoProductName = this.$parent.params.BaseInfoProductName;
      this.form.BaseInfoLot = this.$parent.params.BaseInfoLot;
      this.form.versionNumber = this.$parent.params.versionNumber
      this.form.revNumber = this.$parent.params.revNumber

      this.form.GeneralSpecINCIName = this.inclNameArr.join("_");
      this.form.GeneralSpecCommonName = this.commonNameArr.join("_");
      this.form.GeneralSpecBotanicalName = this.botanicalNameArr.join("_");
      this.form.GeneralSpecPartUsed = this.partUsedArr.join("_");

      this.form.genSpecCustomField = JSON.stringify(this.genSpecCustomFieldAttr) 
      this.form.analysisCustomField = JSON.stringify(this.analysisCustomFieldAttr) 
      this.form.micTestsCustomField = JSON.stringify(this.micTestsCustomFieldAttr) 
      this.form.tableInputCustomField = JSON.stringify(this.tableInputCustomFieldAttr) 


      let jsonStr = JSON.stringify(this.form);
      console.log(jsonStr);
      this.formData.productName = this.form.BaseInfoProductName;
      this.formData.itemCode = this.form.BaseInfoItemCode;
      this.formData.lot = this.form.BaseInfoLot;
      this.formData.jsonValue = jsonStr;
      console.log(this.formData);
      if (params.id) {
        this.form.revDate.push(this.$parent.revDateTime)
        jsonStr = JSON.stringify(this.form);
        this.formData.jsonValue = jsonStr;
        // this.formData.id = params.id;
        this.$api.coa
          .editCoaUrl(this.formData)
          .then(() => {
            this.$message({
              type: "success",
              message: "SuccessFul",
            });
            // this.$router.push({
            //   path: "coa-items",
            // });
            this.$parent.revDateTime = base.methods.getLocalNowTime()
            this.reloadPage(type);
            // this.addToLibrary(this.formData.id)
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
          });
      } else {
        this.$api.coa
          .addCoaUrl(this.formData)
          .then((res) => {
            this.$message({
              type: "success",
              message: "SuccessFul",
            });
            console.log("提交返回成功");
            console.log(res);
            this.$parent.revDateTime = base.methods.getLocalNowTime()
            if(type == 1 || type == 2){
              this.batchAddLibrary(res,type)
            }
            this.$confirm("Return to list page, Continue?", "Tips", {
              confirmButtonText: "Ok",
              cancelButtonText: "Cancel",
              distinguishCancelAndClose: true,
              type: "warning"
            }).then(() => {
              this.$router.push({
                path: "coa-items"
              });
            });

            // this.reloadPage(type);
            // this.$router.push({
            //   path: "coa-items",
            // });
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
          });
      }
    },
    reloadPage(type){
      if (type == "Save&New") {
        //清空数据
        this.$router.push({
          path: "coa-item",
          query: { productType: "Powder" },
        });
        this.$router.go(0);
      }
    },
    getDetailById(params) {
      this.$parent.loading = true;
      this.$api.coa.handleCoaTableUrl(params).then((data) => {
        console.log(data);
        this.formData = data;
        this.form = JSON.parse(data.jsonValue);

        if(this.form.newNoteArr == undefined){
          this.form.newNoteArr = []
          this.newNoteArr = []
        }
        else{
          this.newNoteArr = JSON.parse(this.form.newNoteArr);
        }

        if(this.form.noteTableData){
          this.noteTableData = JSON.parse(this.form.noteTableData);
        }
        if(this.form.revNumber == undefined){
          this.form.revNumber = 0
        }
        if(this.form.revDate == undefined){
          this.form.revDate = []
        }
        console.log('this.form.revDate')
        console.log(this.form.revDate)

        console.log('this.form.revNumber')
        console.log(this.form.revNumber)

        if (this.form.BaseInfoManufacturingDate == "NaN-aN-aN") {
          this.form.BaseInfoManufacturingDate = "";
          this.form.BaseInfoExpirationDate = "";
        }

        if (this.form.productType == "OilEhxto") {
          this.form.productType = "EHXTO";
        } else if (this.productType == "OilShxto") {
          this.form.productType = "SHXTO";
        }
        this.$parent.params = this.form;
        if(this.form.baseInfoCustomField){
          this.$parent.BaseInfoCustomFieldAttr = JSON.parse(this.form.baseInfoCustomField)
          this.baseInfoCustomFieldList = JSON.parse(this.form.baseInfoCustomField)
        }

        this.strSplit(this.form.GeneralSpecINCIName, "inclNameArr");
        this.strSplit(this.form.GeneralSpecCommonName, "commonNameArr");
        this.strSplit(this.form.GeneralSpecBotanicalName, "botanicalNameArr");
        this.strSplit(this.form.GeneralSpecPartUsed, "partUsedArr");

        if (this.form.genSpecCustomField) {
          this.genSpecCustomFieldAttr=JSON.parse(this.form.genSpecCustomField)
        }
        if (this.form.analysisCustomField) {
          this.analysisCustomFieldAttr=JSON.parse(this.form.analysisCustomField)
        }
        if (this.form.micTestsCustomField) {
          this.micTestsCustomFieldAttr=JSON.parse(this.form.micTestsCustomField)
        }
        if (this.form.tableInputCustomField) {
          this.tableInputCustomFieldAttr=JSON.parse(this.form.tableInputCustomField)
        }
        this.$parent.loading = false;
        this.$parent.revDateTime = data.updateTime
      })
      .catch((err) => {
        this.$message({
          type: "error",
          message: err,
        });
        console.log(err);
        this.$parent.loading = false;
      });
    },
    manufacturingDateChange() {
      this.form.BaseInfoManufacturingDate = this.addDate(10,new Date(this.form.BaseInfoManufacturingDate),"day");

      this.form.BaseInfoExpirationDate = this.addDate(this.shelflifeNumber,new Date(this.form.BaseInfoManufacturingDate),"month");
      this.form.BaseInfoExpirationDate = this.addDate(10,new Date(this.form.BaseInfoExpirationDate),"day");

      this.form.BaseInfoManufacturingDate = this.formatTime(this.form.BaseInfoManufacturingDate,"yyyy-MM-dd");
      this.form.BaseInfoExpirationDate = this.formatTime(this.form.BaseInfoExpirationDate,"yyyy-MM-dd");
      
      console.log(this.form.BaseInfoManufacturingDate)
      console.log(this.form.BaseInfoExpirationDate)
    },
    printPage() {
      this.form.BaseInfoItemCode = this.$parent.params.BaseInfoItemCode;
      this.form.BaseInfoProductName = this.$parent.params.BaseInfoProductName;
      this.form.versionNumber = this.$parent.params.versionNumber;
      this.form.BaseInfoLot = this.$parent.params.BaseInfoLot;
      this.printShow = true;
      const style = "@page {margin:0} @media print{}";
      //console.log(this.formModel);
      var printFileName = "";
      // if(this.form.BaseInfoProductName != undefined && this.form.BaseInfoProductName != ""){
      //   printFileName = this.form.BaseInfoProductName.replace(":","~").replace("：", "~");
      // }
      // if(this.form.BaseInfoLot != undefined && this.form.BaseInfoLot != ""){
      //   printFileName = printFileName + this.form.BaseInfoLot;
      // }
      printFileName = "(CoA)"+this.form.BaseInfoProductName+this.form.BaseInfoLot+"Rev."+this.$parent.params.revNumber
      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "printDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);

      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
    aloneprintPage() {
      this.form.BaseInfoItemCode = this.$parent.params.BaseInfoItemCode;
      this.form.BaseInfoProductName = this.$parent.params.BaseInfoProductName;
      this.form.versionNumber = this.$parent.params.versionNumber;
      this.form.BaseInfoLot = this.$parent.params.BaseInfoLot;
      const style = "@page {margin:0;} @media print{}";
      var printFileName = ""; 
      printFileName = "(CoA)"+this.form.BaseInfoProductName+this.form.BaseInfoLot
      
      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "alonePrintDiv",
          type: "html",
          style: style,
          scanStyles: false,
          documentTitle: printFileName,
        });
      }, 1.5);
    },
    tagHandle(array, tagCotext, index) {
      //tag标签点击事件
      console.info(array, tagCotext, index);
      this.$prompt("修改值-->" + tagCotext, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          array.splice(index, 1, value);
        })
        .catch(() => {});
    }, // ingredients标签操作
    handleClose(tag, arr) {
      this[arr].splice(this[arr].indexOf(tag), 1);
    },
    showInput: function (inputVisible, ref) {
      console.log(inputVisible);
      this[inputVisible] = true;
      this.$nextTick(() => {
        this.$refs[ref].$refs.input.focus();
      });
    },
    handleInputConfirm(value, arr, visible) {
      let inputValue = this[value];
      if (inputValue) {
        console.info(this[arr]);
        this[arr].push(inputValue);
      }

      this[visible] = false;
      this[value] = "";
    },
    strSplit(context, type) {
      this[type] = []; //清理旧有数据防止重复
      if (!context) {
        return;
      }
      let splitArr = context.split("_");
      if (splitArr.length == 1) {
        splitArr = context.split(",");
      }
      splitArr.forEach((v) => {
        if (v.trim().length != 0) {
          this[type].push(v);
        }
      });
    },
    addGenSpecCustomRow(type, index) {
      if (type == 1) {
        this.genSpecCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.genSpecCustomFieldAttr, index);
      }
    },
    addAnalysisCustomRow(type, index) {
      if (type == 1) {
        this.analysisCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.analysisCustomFieldAttr, index);
      }
    },
    addMicTestsCustomRow(type, index) {
      if (type == 1) {
        this.micTestsCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.micTestsCustomFieldAttr, index);
      }
    },
    addTableInput(type,index){
      if(type==1){
        this.tableInputCustomFieldAttr.push({ ...this.tableInputField });
      }
      else{
        this.$delete(this.tableInputCustomFieldAttr,index)
      }
    },
    addToLibrary(inventoryType){
      this.inventoryDialogVisible = false;
      let paramsQuery = { ...this.$route.query };
      if (paramsQuery.id) {
        this.batchAddLibrary(paramsQuery.id,inventoryType)
      }
      else{
        this.handleNew(inventoryType)
      }
    },
    batchAddLibrary(id,inventoryType){
      this.$confirm(
        "Are you sure batch add. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        let params ={ids:[id],type:'coa',inventoryType: inventoryType}
        console.log('addToLibrary======params')
        console.log(params)
        this.$api
          .batchAddLibrary(params)
          .then(() => {
            this.$message({ type: "success", message: "batch Add SuccessFully" });
            this.form.libraryRevNumber = this.form.revNumber
            this.form.libraryCreateTime = base.methods.formatTime(base.methods.getLocalNowTime(),"dd/MM/yyyy hh:mm")
            let jsonStr = JSON.stringify(this.form);
            this.formData.jsonValue = jsonStr;
            this.formData.id = id
            this.$api.coa.editCoaUrl(this.formData).then(() => {
              this.$message({
                type: "success",
                message: "SuccessFul",
              });
              this.verifyChange(id)
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err,
              });
              console.log(err);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    fatherMethod(revNumber){
      console.log('revNumber')
      console.log(revNumber)
      this.$parent.params.revNumber = revNumber
    },
    getBaseInfoDetail(newVal){
      this.shelflifeNumber = newVal.shelflifeNumber!= undefined ? parseInt(newVal.shelflifeNumber): 36
      console.log("this.shelflifeNumber==="+this.shelflifeNumber)

      if(newVal.newNoteArr == undefined){
        this.form.newNoteArr = []
        this.newNoteArr = []
      }
      else{
        this.newNoteArr = JSON.parse(newVal.newNoteArr);
      }

      this.form.oldProductType = newVal.productType
      this.form.ProductSubType = newVal.productSubType
      this.form.versionNumber = newVal.versionNumber;
      this.form.revNumber = newVal.revNumber
      
      this.form.GeneralSpecCommonName = newVal.commonName;
      this.form.GeneralSpecINCIName = newVal.inciName;
      this.form.GeneralSpecBotanicalName = newVal.botanicalName;
      this.form.GeneralSpecPartUsed = newVal.partUsed;
      this.form.GeneralSpecAppearance = newVal.appearance;
      this.form.GeneralSpecSolubility = newVal.solubility;
      this.form.GeneralSpecImportantConstituentsInPlant = newVal.ehxtoIcip;
      this.form.GeneralSpecHomogeneity = newVal.ehxtoHomogeneity;
      this.form.GeneralSpecExtractionProcess = newVal.ehxtoExtractionProcess;
      this.form.GeneralSpecCustomRowKey1 = newVal.ehxtoCustomerKey1;
      this.form.GeneralSpecCustomRowValue1 = newVal.ehxtoCustomerValue1;
      //this.form.GeneralSpecProcessingAid=newVal.processingAid;
      //this.form.GeneralSpecSolventForExtraction = newVal.solventForExtracti;
      if (!this.form.GeneralSpecSolventForExtraction) {
        if (newVal.productType == "EHXTO") {
          this.form.GeneralSpecSolventForExtraction =
            newVal.ehxtoSolventExtraction;
        } else {
          this.form.GeneralSpecSolventForExtraction =
            newVal.solventForExtracti;
        }
      }
      // if (!this.form.GeneralSpecSolventForExtraction) {
      //   this.form.GeneralSpecSolventForExtraction = "water";
      // }
      this.form.GeneralSpecCarrier = newVal.ehxtoCarrier;
      //this.form.GeneralSpecPesticides = newVal.ehxtoPesticides;
      if (!this.form.GeneralSpecPesticides) {
        if (newVal.productType == "EHXTO") {
          this.form.GeneralSpecPesticides = newVal.ehxtoPesticides;
        } else {
          this.form.GeneralSpecPesticides = newVal.pesticides;
        }
      }
      if (!this.form.GeneralSpecPesticides) {
        this.form.GeneralSpecPesticides = "Conforms to USP <565>";
      }
      this.form.GeneralSpecCertification = newVal.ehxtoCertification;

      //#region Analysis
      this.form.SpecificationMoisture = newVal.testLimitMoisture;
      this.form.TestMethodMoisture = newVal.testMethodMeshSize;
      this.form.SpecificationSpecificGravity =
        newVal.testLimitSpecificaGravity;
      this.form.TestMethodSpecificGravity = newVal.testMethodSpecificaGravity;
      this.form.SpecificationRefractiveIndex =
        newVal.testLimitRefractiveIndex;
      this.form.TestMethodRefractiveIndex = newVal.testMethodRefractiveIndex;
      this.form.SpecificationOpticalRotation =
        newVal.testLimitOpticalRotation;
      this.form.TestMethodOpticalRotation = newVal.testMethodOpticalRotation;
      this.form.SpecificationTotalHeavyMetals = newVal.testLimitHeavyMetals;
      this.form.TestMethodTotalHeavyMetals = newVal.testMethodHeavyMetals;
      this.form.SpecificationArsenicAS = newVal.testLimitArsenic;
      this.form.TestMethodArsenicAS = newVal.testMethodArsenic;
      this.form.SpecificationCadmiumCd = newVal.testLimitCadmium;
      this.form.TestMethodCadmiumCd = newVal.testMethodCadmium;
      this.form.SpecificationLeadPd = newVal.testLimitLead;
      this.form.TestMethodLeadPd = newVal.testMethodLead;
      this.form.SpecificationMercuryHg = newVal.testLimitMercury;
      this.form.TestMethodMercuryHg = newVal.testMethodMercury;

      this.form.SpecificationCustomRowKey1 =
        newVal.ehxtoTestLimitCustomerKey1;
      this.form.SpecificationCustomRowValue1 =
        newVal.ehxtoTestLimitCustomerValue1;
      this.form.TestMethodCustomRowValue1 =
        newVal.ehxtoTestMethodCustomerValue1;

      this.form.SpecificationCustomRowKey2 =
        newVal.ehxtoTestLimitCustomerKey2;
      this.form.SpecificationCustomRowValue2 =
        newVal.ehxtoTestLimitCustomerValue2;
      this.form.TestMethodCustomRowValue2 =
        newVal.ehxtoTestMethodCustomerValue2;

      this.form.SpecificationCustomRowKey3 =
        newVal.ehxtoTestLimitCustomerKey3;
      this.form.SpecificationCustomRowValue3 =
        newVal.ehxtoTestLimitCustomerValue3;
      this.form.TestMethodCustomRowValue3 =
        newVal.ehxtoTestMethodCustomerValue3;

      this.form.SpecificationCustomRowKey4 =
        newVal.ehxtoTestLimitCustomerKey4;
      this.form.SpecificationCustomRowValue4 =
        newVal.ehxtoTestLimitCustomerValue4;
      this.form.TestMethodCustomRowValue4 =
        newVal.ehxtoTestMethodCustomerValue4;
      //#endregion

      //#region Microbiological Tests
      this.form.SpecificationTotalPlateCount =
        newVal.specificationTotalPlateCount;
      this.form.SpecificationYeastAndMold = newVal.specificationYeastAndMold;
      this.form.SpecificationEColi = newVal.specificationEcoli;
      this.form.SpecificationSalmonella = newVal.specificationSalmonella;
      this.form.SpecificationStaphAureus = newVal.specificationStaphAureus;
      this.form.SpecificationTotalColiforms =
        newVal.specificationTotalColiforms;

      this.form.TestMethodTotalPlateCount = newVal.testMethodTotalPlateCount;
      this.form.TestMethodYeastAndMold = newVal.testMethodYeastAndMold;
      this.form.TestMethodEColi = newVal.testMethodEcoli;
      this.form.TestMethodSalmonella = newVal.testMethodSalmonella;
      this.form.TestMethodStaphAureus = newVal.testMethodTaphAureus;
      this.form.TestMethodTotalColiforms = newVal.testMethodTotalColiforms;

      this.form.MTSpecificationCustomRowKey1 =
        newVal.microbiologicalCustomerKey1;
      this.form.MTSpecificationCustomRowValue1 =
        newVal.microbiologicalCustomerValue1;
      this.form.MTTestMethodCustomRowValue1 =
        newVal.microbiologicalTestMethodCustomerValue1;
      //#endregion

      this.form.genSpecCustomField = newVal.genSpecCustomField;
      this.form.analysisCustomField = newVal.analysisCustomField;
      this.form.micTestsCustomField = newVal.micTestsCustomField;
      this.form.tableInputCustomField = newVal.tableInputCustomField;
      this.form.tableInput1 = newVal.tableInput1;
      this.form.tableInput2 = newVal.tableInput2;
      this.form.tableInput3 = newVal.tableInput3;
      this.form.tableInput4 = newVal.tableInput4;
      this.form.tableInput5 = newVal.tableInput5;
      
      if (this.form.genSpecCustomField) {
        this.genSpecCustomFieldAttr=JSON.parse(this.form.genSpecCustomField)
      }
      if (this.form.analysisCustomField) {
        this.analysisCustomFieldAttr=JSON.parse(this.form.analysisCustomField)
      }
      if (this.form.micTestsCustomField) {
        this.micTestsCustomFieldAttr=JSON.parse(this.form.micTestsCustomField)
      }        
      if (this.form.tableInputCustomField) {
        this.tableInputCustomFieldAttr=JSON.parse(this.form.tableInputCustomField)
      }

      this.strSplit(this.form.GeneralSpecINCIName, "inclNameArr");
      this.strSplit(this.form.GeneralSpecCommonName, "commonNameArr");
      this.strSplit(this.form.GeneralSpecBotanicalName, "botanicalNameArr");
      this.strSplit(this.form.GeneralSpecPartUsed, "partUsedArr");
      console.log(this.form);
      console.log("=======OilEhxto-baseInfoItemCodeDetail-end=========");
    },
    addNewNoteTableClick(){
      this.newNoteArr.push({
        content:this.newNote
      })
      this.newNote = ''
    },
    deleteNewNoteTableClick(index){
      this.newNoteArr.splice(index,1);
      // this.$delete(this.newNoteArr,index)
    },
    verifyChange(pId=0) {
      console.log("pId==="+pId)
      let params = {
        id: pId !=0 ? pId : this.$route.query.id,
        isFlag: true,
      };
      this.$api.coa
        .verfiedCoaItem(params)
        .then((data) => {
          console.log(data);
          this.$message({ type: "success", message: "Verified SuccessFul" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
    if(userInfo){
      this.formData.updateName = userInfo.username
    }
  },
  mounted() {
    let params = { ...this.$route.query };
    if (params.id) {
      if(params.aloneprint){
        this.isShowAlonePrint = params.aloneprint == "1" ? true : false
      }
      if(params.showUpdate){
        this.isShowUpdate = params.showUpdate == "1" ? true : false
      }
      this.getDetailById(params);
    }
    let localData = localStorage.getItem("tpsDataKey");
    let data;
    if (localData != "undefined") {
      data = JSON.parse(localStorage.getItem("tpsDataKey"));
    }

    if (data) {
      Object.keys(data).forEach((key) => {
        this.form[key] = data[key];
      });
    }
  },
  watch: {
    baseInfoItemCodeDetail: {
      handler(newVal) {
        console.log("=======OilEhxto-baseInfoItemCodeDetail-start=========");
        console.log(newVal); //GeneralSpecCustomRowKey1
        this.getBaseInfoDetail(newVal)
      },
      //为了发现内部的内容发生了变化把状态改变成true
      deep: true,
      immediate: true,//首次赋值的时候也会触发watch里面的handler事件
    },
    baseinfoCustom: {
      handler(newVal) {
        console.log("======Liquid-baseinfoCustombaseinfoCustombaseinfoCustombaseinfoCustom-start==========");
        this.baseInfoCustomFieldList = newVal
        console.log(newVal);
      },
      //为了发现内部的内容发生了变化把状态改变成true
      deep: true,
      immediate: true //首次赋值的时候也会触发watch里面的handler事件
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../module/NewItemStyle.scss";
</style>
<style>
@import "../module/CoaStyle.css";
</style>


